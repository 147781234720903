import { Injectable } from '@angular/core';

import { WorkCenterModel } from '@models/work-center.model';

import { AuthService } from '@app/shared/services/auth.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GlobalFilterStorageService {
    private typeStorageKey = 'filter-type';
    private employeeIdStorageKey = 'employee-id-filter';
    private userFilterStorageKey = 'user-filter';
    private workCenterStorageKey = 'work-center-filter';
    private localizationCenterStorageKey = 'localization-center-filter';

    constructor(private authService: AuthService) {
        this.authService.logoutSubject.pipe(take(1)).subscribe(() => this.logoutCleanup());
    }

    filterChangedSubject = new Subject();

    setFilterType(value: string) {
        localStorage.setItem(this.typeStorageKey, value);
    }

    getFilterType(): string | null {
        return localStorage.getItem(this.typeStorageKey);
    }

    setEmployeeId(value: string) {
        localStorage.setItem(this.employeeIdStorageKey, value);
    }

    getEmployeeId(): string {
        return localStorage.getItem(this.employeeIdStorageKey);
    }

    setLocalizationCenter(value: string) {
        localStorage.setItem(this.localizationCenterStorageKey, value);
    }

    getLocalizationCenter(): string | null {
        const localizationCenter = JSON.parse(
            localStorage.getItem(this.localizationCenterStorageKey)
        );
        return localizationCenter ? localizationCenter.toString() : null;
    }

    setUserFilter(filter: any) {
        localStorage.setItem(this.userFilterStorageKey, JSON.stringify(filter));
    }

    getUserFilter() {
        return JSON.parse(localStorage.getItem(this.userFilterStorageKey));
    }

    clearUserFilter() {
        localStorage.removeItem(this.userFilterStorageKey);
    }

    setWorkCenters(workCenter: WorkCenterModel[]) {
        localStorage.setItem(this.workCenterStorageKey, JSON.stringify(workCenter));
    }

    getWorkCenters(): WorkCenterModel[] {
        return JSON.parse(localStorage.getItem(this.workCenterStorageKey));
    }

    clearSelectedWorkCenters(): void {
        localStorage.removeItem(this.workCenterStorageKey);
    }

    private logoutCleanup() {
        this.clearUserFilter();
        this.clearSelectedWorkCenters();
    }
}
