import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '@environment/environment';
import { SupportedLanguages } from '@utils/enums/supported-languages.enum';

import { MessageService } from 'primeng/api';

import { AppComponent } from './app.component';

import { HttpLoadingInterceptor } from '@app/shared/interceptors/http-loading.inteceptor';
import { NgxCaptureModule } from 'ngx-capture';
import { SharedModule } from './shared/shared.module';

import { MsalGuard, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedLayoutComponent } from './modules/authenticated-layout/authenticated-layout.component';
import { HttpRenewExpiredTokenInterceptor } from './shared/interceptors/http-renew-expired-token.interceptor';
import { AccessControlService } from './shared/services/access-control.service';

@NgModule({
    declarations: [AppComponent, AuthenticatedLayoutComponent],
    imports: [
        BrowserModule,
        HttpClientModule,

        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.msalAuth.clientId,
                    authority: environment.msalAuth.authority,
                    redirectUri: environment.msalAuth.redirectUri,
                    navigateToLoginRequestUrl: false,
                },
            }),
            null,
            null
        ),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: SupportedLanguages.PT,
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        NgxCaptureModule,
    ],
    exports: [TranslateModule, SharedModule],
    providers: [
        MessageService,
        MsalGuard,
        { provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRenewExpiredTokenInterceptor, multi: true },
        AccessControlService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
