import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BuildDetailsModel } from '@app/shared/models/build-details.model';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, timer } from 'rxjs';
import { DynamicConfirmationModalComponent } from '../../components/dynamic-confirmation-modal/dynamic-confirmation-modal.component';
import {
    DynamicConfirmationButtonModel,
    DynamicConfirmationModalModel,
} from '../../components/dynamic-confirmation-modal/dynamic-confirmation-modal.model';
import { AuthService } from '../auth.service';
import { TranslateService } from '../translate.service';
import { BuildDetailsService } from './build-details.service';

@Injectable({ providedIn: 'root' })
export class CheckAppVersionService {
    private localVersion: BuildDetailsModel;
    private timerSubscription: Subscription;

    constructor(
        private buildDetailsService: BuildDetailsService,
        private router: Router,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private authService: AuthService
    ) {}

    init(): void {
        this.getLocalStorageVersion();
        this.checkVersionPeriodically();
        this.getUserNavigation();
    }

    getLocalStorageVersion(): void {
        this.localVersion =
            JSON.parse(localStorage.getItem('buildDetails')) || new BuildDetailsModel();
    }

    private checkVersionPeriodically(): void {
        const interval = 10800000;
        this.timerSubscription = timer(0, interval).subscribe(() => {
            this.checkBuildNumber();
        });
    }

    checkBuildNumber(): void {
        this.buildDetailsService.getBuildDetails().subscribe(response => {
            const serverVersion = response;
            if (this.localVersion.buildNumber !== serverVersion.buildNumber) {
                this.resetCache(serverVersion);
                this.openNewVersionModal();
            }
            this.localVersion = response;
        });
    }

    resetCache(serverVersion: BuildDetailsModel): void {
        localStorage.clear();
        localStorage.setItem('buildDetails', JSON.stringify(serverVersion));
        this.authService.setUserDataAccessLocalStorage(this.authService.userAuthData);
    }

    getUserNavigation(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.checkBuildNumber();
                this.timerSubscription.unsubscribe();
                this.checkVersionPeriodically();
            }
        });
    }

    async openNewVersionModal(): Promise<void> {
        const message = await this.translateService.getTranslation('GLOBAL.NewAppVersion');

        const buttons: DynamicConfirmationButtonModel[] = [
            new DynamicConfirmationButtonModel({
                label: 'DEFAULT_TEXT.Refresh',
                styleClass: 'filled',
                action: () => true,
            }),
        ];

        const data: DynamicConfirmationModalModel = {
            contentList: [message],
            contentAsText: true,
            translateContent: false,
            buttons,
        };

        this.dialogService
            .open(DynamicConfirmationModalComponent, {
                data,
                width: '30vw',
                header: await this.translateService.getTranslation('GLOBAL.Warning'),
                closable: false,
            })
            .onClose.subscribe(() => {
                location.href = location.origin + location.pathname + `?v=${new Date().getTime()}`;
            });
    }
}
