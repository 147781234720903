import { FunctionalLocationModel } from './functional-location.model';
import { TableFiltersModel } from './paginated-request.model';
import { ShutdownInfoModel } from './shutdown-info.model';

export class FunctionalLocationGroupModel {
    constructor(
        public name = '',
        public description = '',
        public employeeId = '',
        public createdDate = new Date(),
        public updatedOn = '',
        public type: number | string = null,
        public planningCenter = '',
        public localizationCenter = '',
        public phaseId: number = null,
        public members: FunctionalLocationModel[] = [],
        public coiInformation = new ShutdownInfoModel(),
        public groupBelongProductionProcess = true,
        public groupBelongProductionProcessPercentage = 100,
        public groupManagerByCOI = false,
        public id?: number,
        public defaultAlternates?: string[],
        public displayName?: string
    ) {}
}

export class FunctionalLocationGroupImportModel {
    fileName: string;
    hashFile: string;
    items: any[];
}

export class FunctionalLocationGroupFilterModel extends TableFiltersModel {
    constructor(
        public localizationCenters: string[] = [],
        public createdBy: string[] = [],
        public hiddenFleet: boolean = false,
        public phases?: number[],
        public functionalLocationGroupIds: number[] = [],
        public additionalFilters?: { [col: string]: string },
        public exportTemplate: boolean = false
    ) {
        super(-1);
    }
}
