import { HttpParams } from '@angular/common/http';
import { FunctionalLocationGroupFilterModel } from '@app/shared/models/functional-location-group.model';
import { environment } from '@environment/environment';

const BASE_FUNCTIONAL_LOCATION_GROUP_URL = `${environment.apiUrl}/functional-location-group`;

const POST_FUNCTIONAL_LOCATION_GROUP = () => `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}`;

const GET_FUNCTIONAL_LOCATION_GROUP_BY_FILTER = () =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/search`;

const GET_FUNCTIONAL_LOCATION_GROUP_BY_ID = (id: number) =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/${id}`;

const PUT_FUNCTIONAL_LOCATION_GROUP_BY_ID = (id: number) =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/${id}`;

const DELETE_FUNCTIONAL_LOCATION_GROUP_BY_ID = (id: number) =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/${id}`;

const GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_GROUP = () =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/export`;

const GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_FUNCTIONAL_LOCATION = () => {
    return `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/export/functional-location`;
};

const POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_GROUP = () =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/import`;
const POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_FUNCTIONAL_LOCATION = () =>
    `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}/import/functional-location`;

const GET_TYPES = () => `${BASE_FUNCTIONAL_LOCATION_GROUP_URL}-type`;

function getParamsList(filter: FunctionalLocationGroupFilterModel): HttpParams {
    let params = new HttpParams();

    for (const key in filter) {
        if (!Array.isArray(filter[key])) continue;

        filter[key]?.map(element => {
            params = params.append(key, element);
        });
    }

    params = params.append('hiddenFleet', filter.hiddenFleet);
    params = params.append('exportTemplate', filter.exportTemplate ?? false);

    return params;
}

export const FUNCTIONAL_LOCATION_GROUP_URL = {
    POST_FUNCTIONAL_LOCATION_GROUP,
    GET_FUNCTIONAL_LOCATION_GROUP_BY_FILTER,
    GET_FUNCTIONAL_LOCATION_GROUP_BY_ID,
    PUT_FUNCTIONAL_LOCATION_GROUP_BY_ID,
    DELETE_FUNCTIONAL_LOCATION_GROUP_BY_ID,
    GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_GROUP,
    GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_FUNCTIONAL_LOCATION,
    POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_GROUP,
    POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_FUNCTIONAL_LOCATION,
    GET_TYPES,
};
