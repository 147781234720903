import { MaintenanceShutdownStatusTypeEnum } from '@app/core/utils/enums/maintenance-shutdown-status.enum';
import { WorkflowRolesEnum } from '@app/core/utils/enums/workflow-roles.enum';
import { TableFiltersModel } from '@app/shared/models/paginated-request.model';

export class GlobalFilterModel extends TableFiltersModel {
    planningCenters: string[] = [];
    localizationCenters: string[] = [];
    functionalLocationGroup: number[] = [];
    startDate = new Date(new Date().getFullYear(), 0, 1);
    endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59);
    createdBy: string[] = [];
    owners: string[] = [];
    name: string;
    ownerOrAlternate: number;
    maintenanceShutdownStatus: MaintenanceShutdownStatusTypeEnum =
        MaintenanceShutdownStatusTypeEnum.All;
    maintenanceShutdownBigShutdown: boolean;
    maintenanceShutdownAdherenceByDateStatus: number[] = [];
    maintenanceShutdownAdherenceByDurationStatus: number[] = [];
    workshop: string;
    startCreatedDate: Date | null = null;
    endCreatedDate: Date | null = null;
    executiveManagement: string[] = [];
    areaManagement: string[] = [];
    phase: string[] = [];
    system: string[] = [];
    category: string[] = [];
    projectDemandStatus: number;
    projectDemandRole: number;
    systemStatus: string;
    ordersStatus: number;
    priorizationRange: number[] = [];
    fullDescription: string;
    hasProjectDemands: boolean | null;
    emailTypeId: number[] = [];
    emailStatus: boolean | null = null;
    startWorkflowDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    endWorkflowDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59);
    workflowId: string;
    maintenanceShutdownId: string;
    workflowRole: WorkflowRolesEnum;
    workflowStatusId: number[] = [];
    plannedDateStart: Date | null = null;
    plannedDateEnd: Date | null = null;
    functionalLocationGroupTypes: number[] = [];
    maintenanceShutdownIdsToSearch: string;
    isStrategicShutdownEngineering: boolean;
    hasMaintenanceShutdown: boolean | null;
}
