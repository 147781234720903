import { Component, OnInit, signal } from '@angular/core';

import { ROUTES } from '@core/constants/routes';
import { MenuItem } from 'primeng/api';

import { FILE_MANAGEMENT } from '@app/core/constants/urls/file-management.url';
import { getDefaultLanguageModelOptions, LanguageModel } from '@app/shared/models/language.model';
import { FileManagementService } from '@app/shared/services/api/file-management.service';
import { LogoutService } from '@app/shared/services/api/logout.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ScreenSizeService } from '@app/shared/services/screen-size.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { SupportedLanguages } from '@utils/enums/supported-languages.enum';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicModalButtonModel, DynamicModalModel } from '../dynamic-modal/dynamic-modal.model';
import { PdfViewModalComponent } from '../pdf-view-modal/pdf-view-modal.component';
import { UserManualUploadModalComponent } from '../user-manual-upload-modal/user-manual-upload-modal.component';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    languages: LanguageModel[] = getDefaultLanguageModelOptions();

    selectedLanguage: string;
    selectedLanguageLabel: string;

    userMenuItems: MenuItem[];
    isMobile = signal(false);
    observers = new Subscription();
    menuOpened = signal(false);

    constructor(
        private translateService: TranslateService,
        private authService: AuthService,
        private logoutService: LogoutService,
        private dialogService: DialogService,
        private screenSizeService: ScreenSizeService,
        private fileManagementService: FileManagementService
    ) {}

    ngOnInit(): void {
        this.selectedLanguage =
            this.translateService.getCurrentLanguage()?.toUpperCase() ?? SupportedLanguages.PT;
        this.selectedLanguageLabel = this.languages.find(
            language => language.code === this.selectedLanguage
        ).name;
        this.setUserMenuItems();
        this.observers = this.screenSizeService.isMobile$.subscribe(isMobile =>
            this.isMobile.set(isMobile)
        );
    }

    ngOnDestroy(): void {
        this.observers.unsubscribe();
    }

    private async setUserMenuItems(): Promise<void> {
        this.userMenuItems = [
            {
                label: await this.authService.getEmployeeId(),
                icon: 'pi pi-id-card',
                disabled: true,
                styleClass: 'user-data',
            },
            {
                label: await this.authService.getEmployeeName(),
                icon: 'pi pi-user',
                disabled: true,
                styleClass: 'user-data',
            },
            {
                label: (await this.authService.getEmployeeRoles()).join(', '),
                icon: 'pi pi-lock',
                disabled: true,
                styleClass: 'user-data',
            },
            {
                separator: true,
            },
            {
                label: await this.translateService.getTranslation('GLOBAL.Settings'),
                icon: 'pi pi-cog',
                routerLink: ROUTES.userSettings,
            },
            {
                label: await this.translateService.getTranslation('USER.FILTER.Title'),
                icon: 'pi pi-user-plus',
                routerLink: ROUTES.user.filter.index,
            },
            {
                label: await this.translateService.getTranslation('MENU.Help'),
                icon: 'pi pi-question-circle',
                items: await this.setHelpItems(),
            },
            {
                label: await this.translateService.getTranslation('MENU.Logout'),
                icon: 'pi pi-sign-out',
                command: () => {
                    this.logout();
                },
            },
        ];
    }

    async setHelpItems(): Promise<MenuItem[]> {
        return [
            {
                label: 'FAQ',
                routerLink: ROUTES.faq.index,
                icon: 'pi pi-question-circle',
            },
            {
                label: await this.translateService.getTranslation('OPERATIONS_MANUAL.UpdateManual'),
                command: () => this.openUserManualUploadModal(),
                icon: 'pi pi-upload',
                visible: this.authService.isAdministrator,
            },
            {
                label: await this.translateService.getTranslation('OPERATIONS_MANUAL.Text'),
                command: () => this.openUserManualViewModal(),
                icon: 'pi pi-book',
            },
            {
                label: await this.translateService.getTranslation('BREADCRUMB.Notice'),
                routerLink: ROUTES.notice.index,
                icon: 'pi pi-envelope',
            },
        ];
    }

    changeLanguage(): void {
        this.translateService.setLanguage(SupportedLanguages[this.selectedLanguage]);
        this.setUserMenuItems();
    }

    logout(): void {
        this.logoutService.clearAccessToken().subscribe();
        this.authService.logout();
    }

    async openUserManualUploadModal(): Promise<void> {
        this.dialogService.open(UserManualUploadModalComponent, {
            header: await this.translateService.getTranslation('OPERATIONS_MANUAL.UpdateManual'),
            width: '30vw',
        });
    }

    async openUserManualViewModal(): Promise<void> {
        const data = new DynamicModalModel([
            new DynamicModalButtonModel({
                label: 'DEFAULT_TEXT.DownloadFile',
                icon: 'pi pi-download',
                type: 'filled',
                action: async () => {
                    await this.fileManagementService.downloadUserManual();
                    return false;
                },
                disableFunction: null,
            }),
            new DynamicModalButtonModel({
                label: 'OPERATIONS_MANUAL.UpdateManual',
                icon: 'pi pi-upload',
                type: 'ghost',
                action: () => {
                    this.openUserManualUploadModal();
                    return true;
                },
                disableFunction: null,
            }),
        ]);

        this.dialogService.open(PdfViewModalComponent, {
            header: await this.translateService.getTranslation('OPERATIONS_MANUAL.Text'),
            width: '80vw',
            data: {
                ...data,
                url: FILE_MANAGEMENT.DOWNLOAD_USER_MANUAL(),
                errorMessage: await this.translateService.getTranslation(
                    'OPERATIONS_MANUAL.ErrorMessage'
                ),
            },
        });
    }

    setMenuOpened(menuOpened: boolean): void {
        this.menuOpened.set(menuOpened);
    }
}
