<app-dynamic-modal [cancelButtonVisible]="false" [data]="data">
    @if (pdfSrc) {
        <pdf-viewer
            [src]="pdfSrc"
            [render-text]="true"
            [original-size]="false"
            (after-load-complete)="pdfLoadCompleted()"
            (error)="pdfLoadError($event)"></pdf-viewer>
    }
</app-dynamic-modal>
