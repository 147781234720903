import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { LoadingService } from '@app/shared/services/loading.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { PDFSource } from 'ng2-pdf-viewer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicModalModel } from '../dynamic-modal/dynamic-modal.model';

@Component({
    selector: 'app-pdf-view-modal',
    templateUrl: './pdf-view-modal.component.html',
    styleUrl: './pdf-view-modal.component.scss',
})
export class PdfViewModalComponent implements OnInit {
    pdfSrc: PDFSource;
    data?: DynamicModalModel;

    constructor(
        private httpClient: HttpClient,
        private dynamicDialogConfig: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef,
        private loadingService: LoadingService,
        private systemMessageService: SystemMessageService
    ) {}

    ngOnInit(): void {
        this.loadingService.setLoading(true, this.dynamicDialogConfig.data.url);
        this.pdfSrc = {
            url: this.dynamicDialogConfig.data.url,
            httpHeaders: this.httpClient.setGlobalHeaders(),
        };

        this.data = this.dynamicDialogConfig.data as DynamicModalModel;
    }

    pdfLoadCompleted(): void {
        this.loadingService.setLoading(false, this.dynamicDialogConfig.data.url);
    }

    pdfLoadError(error): void {
        if (error.name === 'MissingPDFException') {
            this.loadingService.setLoading(false, this.dynamicDialogConfig.data.url);
            this.systemMessageService.notifyWarning(this.dynamicDialogConfig.data.errorMessage);
            this.dialogRef.close();
        }
    }
}
