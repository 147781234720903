<div class="type-area">
    <p-tabView [(activeIndex)]="type" (onChange)="onFilterTypeChanged()">
        <!-- Group Tab -->
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-users"></i>
                <span for="mode1" class="radio-label">{{
                    'FILTER.PersonalLabel' | translate
                }}</span>
            </ng-template>
            <div>
                <div class="filter-item">
                    <span for="networkKey" class="filter-label">{{
                        'FILTER.KeyPlaceholder' | translate
                    }}</span>
                    <input
                        pInputText
                        id="networkKey"
                        type="text"
                        [placeholder]="'FILTER.KeyPlaceholder' | translate"
                        [(ngModel)]="employeeId"
                        (change)="onEmployeeIdChanged()" />
                </div>
                <div>
                    <span for="userFilter" class="filter-label">
                        {{ 'FILTER.Personal' | translate }}
                    </span>
                    <p-dropdown
                        id="userFilter"
                        [placeholder]="
                            (!userFilterLoading
                                ? 'FILTER.PersonalPlaceholder'
                                : 'DEFAULT_TEXT.Loading'
                            ) | translate
                        "
                        [disabled]="userFilterDisabled && userFilterLoading"
                        [options]="userFiltersList"
                        [(ngModel)]="userFilter"
                        optionLabel="name"
                        (onChange)="onUserFilterChanged()"
                        [resetFilterOnHide]="true"
                        [showClear]="true">
                    </p-dropdown>
                </div>
            </div>
        </p-tabPanel>

        <!-- Selection/Manual Tab -->
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-check"></i>
                <span for="mode2" class="radio-label">{{
                    'FILTER.SelectionLabel' | translate
                }}</span>
            </ng-template>
            <div>
                <div class="filter-item">
                    <span for="localizationCenter" class="filter-label">
                        {{ 'FILTER.LocalizationCenter' | translate }}
                    </span>
                    <p-dropdown
                        *ngIf="!localizationCenterLoading"
                        id="localizationCenter"
                        [options]="localizationCenterList"
                        optionValue="code"
                        optionLabel="code"
                        [(ngModel)]="localizationCenter"
                        [filter]="true"
                        filterBy="code"
                        [placeholder]="'FILTER.LocalizationCenterPlaceholder' | translate"
                        (onChange)="onLocalizationCenterChanged()"
                        [showClear]="true">
                    </p-dropdown>
                </div>
                <div>
                    <span for="workCenter" class="filter-label">
                        {{ 'FILTER.WorkCenter' | translate }}
                    </span>
                    <div class="filter-multi-select">
                        <p-multiSelect
                            [options]="workCenterList"
                            [(ngModel)]="workCenters"
                            optionLabel="name"
                            showToggleAll="true"
                            [placeholder]="
                                (!workCentersLoading
                                    ? 'FILTER.WorkCenterPlaceholder'
                                    : 'DEFAULT_TEXT.Loading'
                                ) | translate
                            "
                            [filter]="true"
                            filterBy="name"
                            [selectedItemsLabel]="optionLabel"
                            (onPanelHide)="onWorkCenterPanelHide()"
                            (onChange)="onWorkCenterChanged($event)"
                            [disabled]="workCentersDisabled"
                            [resetFilterOnHide]="true">
                        </p-multiSelect>
                        <button
                            pButton
                            type="button"
                            icon="pi pi-times"
                            class="b-gray"
                            (click)="workCenters = []; onWorkCenterPanelHide()"
                            [disabled]="!workCenters || workCenters.length === 0"></button>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
